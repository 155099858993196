
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ContentTableCheck from "@/components/reusable/policy-information/house-insurance/ContentTableCheck.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import * as Yup from "yup";

export default defineComponent({
  name: "report-contents-house-insurance-claim",
  components: { Form, Field, ErrorMessage, ImagesUploader, ContentTableCheck },
  prop: ["publicId"],
  data() {
    return {
      hasDamaged: false,
      hasPoliceReport: false,
      hasItems: false,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      minimumDate: "",
      todaysDate: "",
      amount: "",
      affectedAreaPicture: "",
      policeReport: "",
      descriptionOfIncident: "",
      acceptTerms: false,
      policy: "",
      policies: [],
      content: [],
      damagedItemsImages: "",
      estimateClass: "col-xl-6"

      // publicId: this.$route.params.publicId,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const claimNatures = [
      {
        id: 1,
        name: "Accidental Damage",
        code: "AD",
        booleanName: "isAccidentClaim"
      },
      {
        id: 2,
        name: "Fire Damage",
        code: "FD",
        booleanName: "isFireClaim"
      },
      {
        id: 3,
        name: "Theft",
        code: "TFT",
        booleanName: "isTheftClaim"
      }
    ];

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed().required().label("Policy reference"),
      // itemPublicId: Yup.mixed()
      //   .required()
      //   .label("policy benefit limit"),
      claimNature: Yup.mixed().required().label("Claim nature"),
      lossDate: Yup.date().required().label("Date and time of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions")
    });

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.CLAIMS_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history"
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Personal Effects", [
        "Claims",
        "Report Claims",
        "House Insurance"
      ]);
    });

    return { submitButton1, claimsValidator, claimNatures, submitForm };
  },
  created() {
    // Get the current date
    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    //Set page title
    document.title =
      "Personal Effects House Insurance Claim | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/package/HOU/CON`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(event) {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.POLICIES_ROUTE}/${event.target.value}/items`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.hasItems = true;
            this.content = data.data[0].content;
            this.minimumDate = data.data[0].policyStartDate;
            this.itemPublicId = data.data[0].publicId;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getNatureOfIncident(event) {
      this.defaultValues();

      const value = event.target.options[event.target.selectedIndex].value;

      // If the claim nature is accident
      if (value == "Accidental Damage" || value == "Fire Damage") {
        this.hasDamaged = true;
      } else if (value == "Theft") {
        this.hasDamaged = true;
        this.hasPoliceReport = true;
        this.estimateClass = "col-xl-4";
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      this.todaysDate = variables.currentDate();
    },
    defaultValues() {
      this.hasDamaged = false;
      this.hasPoliceReport = false;
      this.estimateClass = "col-xl-6";
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    }
  }
});
